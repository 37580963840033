// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "./common";

/*
Skeletons

Markup:
<div class="#{$ns}-card">
  <h5 class="#{$ns}-heading"><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="#{$ns}-button #{$ns}-icon-add {{.modifier}}" tabindex="-1">Submit</button>
</div>

.#{$ns}-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide skeleton
*/

@keyframes skeleton-glow {
  from {
    border-color: $skeleton-color-start;
    background: $skeleton-color-start;
  }

  to {
    border-color: $skeleton-color-end;
    background: $skeleton-color-end;
  }
}

// This class hides content with a glowing, rounded rectangle.
// CSS properties that should always override consumer values use the "!important" rule.
/* stylelint-disable declaration-no-important */
.#{$ns}-skeleton {
  border-color: $skeleton-color-start !important;
  border-radius: 2px;
  box-shadow: none !important;

  // do not !important this for Firefox support
  background: $skeleton-color-start;

  // Prevent background color from extending to the border and overlappping
  background-clip: padding-box !important;
  cursor: default;

  // Transparent text will occupy space but be invisible to the user
  color: transparent !important;
  animation: $skeleton-animation;
  pointer-events: none;
  user-select: none;

  // Make pseudo-elements (CSS icons) and children invisible
  &::before,
  &::after,
  * {
    visibility: hidden !important;
  }
}
/* stylelint-enable declaration-no-important */
