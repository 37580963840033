/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */

$pt-icon-add: "\e63e";
$pt-icon-add-column-left: "\e6f9";
$pt-icon-add-column-right: "\e6fa";
$pt-icon-add-row-bottom: "\e6f8";
$pt-icon-add-row-top: "\e6f7";
$pt-icon-add-to-artifact: "\e67c";
$pt-icon-add-to-folder: "\e6d2";
$pt-icon-airplane: "\e74b";
$pt-icon-align-center: "\e603";
$pt-icon-align-justify: "\e605";
$pt-icon-align-left: "\e602";
$pt-icon-align-right: "\e604";
$pt-icon-alignment-bottom: "\e727";
$pt-icon-alignment-horizontal-center: "\e726";
$pt-icon-alignment-left: "\e722";
$pt-icon-alignment-right: "\e724";
$pt-icon-alignment-top: "\e725";
$pt-icon-alignment-vertical-center: "\e723";
$pt-icon-annotation: "\e6f0";
$pt-icon-application: "\e735";
$pt-icon-applications: "\e621";
$pt-icon-archive: "\e907";
$pt-icon-arrow-bottom-left: "\2199";
$pt-icon-arrow-bottom-right: "\2198";
$pt-icon-arrow-down: "\2193";
$pt-icon-arrow-left: "\2190";
$pt-icon-arrow-right: "\2192";
$pt-icon-arrow-top-left: "\2196";
$pt-icon-arrow-top-right: "\2197";
$pt-icon-arrow-up: "\2191";
$pt-icon-arrows-horizontal: "\2194";
$pt-icon-arrows-vertical: "\2195";
$pt-icon-asterisk: "\002a";
$pt-icon-automatic-updates: "\e65f";
$pt-icon-badge: "\e6e3";
$pt-icon-ban-circle: "\e69d";
$pt-icon-bank-account: "\e76f";
$pt-icon-barcode: "\e676";
$pt-icon-blank: "\e900";
$pt-icon-blocked-person: "\e768";
$pt-icon-bold: "\e606";
$pt-icon-book: "\e6b8";
$pt-icon-bookmark: "\e61a";
$pt-icon-box: "\e6bf";
$pt-icon-briefcase: "\e674";
$pt-icon-bring-data: "\e90a";
$pt-icon-build: "\e72d";
$pt-icon-calculator: "\e70b";
$pt-icon-calendar: "\e62b";
$pt-icon-camera: "\e69e";
$pt-icon-caret-down: "\2304";
$pt-icon-caret-left: "\2329";
$pt-icon-caret-right: "\232A";
$pt-icon-caret-up: "\2303";
$pt-icon-cell-tower: "\e770";
$pt-icon-changes: "\e623";
$pt-icon-chart: "\e67e";
$pt-icon-chat: "\e689";
$pt-icon-chevron-backward: "\e6df";
$pt-icon-chevron-down: "\e697";
$pt-icon-chevron-forward: "\e6e0";
$pt-icon-chevron-left: "\e694";
$pt-icon-chevron-right: "\e695";
$pt-icon-chevron-up: "\e696";
$pt-icon-circle: "\e66a";
$pt-icon-circle-arrow-down: "\e68e";
$pt-icon-circle-arrow-left: "\e68c";
$pt-icon-circle-arrow-right: "\e68b";
$pt-icon-circle-arrow-up: "\e68d";
$pt-icon-citation: "\e61b";
$pt-icon-clean: "\e7c5";
$pt-icon-clipboard: "\e61d";
$pt-icon-cloud: "\2601";
$pt-icon-cloud-download: "\e690";
$pt-icon-cloud-upload: "\e691";
$pt-icon-code: "\e661";
$pt-icon-code-block: "\e6c5";
$pt-icon-cog: "\e645";
$pt-icon-collapse-all: "\e763";
$pt-icon-column-layout: "\e6da";
$pt-icon-comment: "\e68a";
$pt-icon-comparison: "\e637";
$pt-icon-compass: "\e79c";
$pt-icon-compressed: "\e6c0";
$pt-icon-confirm: "\e639";
$pt-icon-console: "\e79b";
$pt-icon-contrast: "\e6cb";
$pt-icon-control: "\e67f";
$pt-icon-credit-card: "\e649";
$pt-icon-cross: "\2717";
$pt-icon-crown: "\e7b4";
$pt-icon-cube: "\e7c8";
$pt-icon-cube-add: "\e7c9";
$pt-icon-cube-remove: "\e7d0";
$pt-icon-curved-range-chart: "\e71b";
$pt-icon-cut: "\e6ef";
$pt-icon-dashboard: "\e751";
$pt-icon-data-lineage: "\e908";
$pt-icon-database: "\e683";
$pt-icon-delete: "\e644";
$pt-icon-delta: "\0394";
$pt-icon-derive-column: "\e739";
$pt-icon-desktop: "\e6af";
$pt-icon-diagnosis: "\e90d";
$pt-icon-diagram-tree: "\e7b3";
$pt-icon-direction-left: "\e681";
$pt-icon-direction-right: "\e682";
$pt-icon-disable: "\e600";
$pt-icon-document: "\e630";
$pt-icon-document-open: "\e71e";
$pt-icon-document-share: "\e71f";
$pt-icon-dollar: "\0024";
$pt-icon-dot: "\2022";
$pt-icon-double-caret-horizontal: "\e6c7";
$pt-icon-double-caret-vertical: "\e6c6";
$pt-icon-double-chevron-down: "\e703";
$pt-icon-double-chevron-left: "\e6ff";
$pt-icon-double-chevron-right: "\e701";
$pt-icon-double-chevron-up: "\e702";
$pt-icon-doughnut-chart: "\e6ce";
$pt-icon-download: "\e62f";
$pt-icon-drag-handle-horizontal: "\e716";
$pt-icon-drag-handle-vertical: "\e715";
$pt-icon-draw: "\e66b";
$pt-icon-drive-time: "\e615";
$pt-icon-duplicate: "\e69c";
$pt-icon-edit: "\270E";
$pt-icon-eject: "\23cf";
$pt-icon-endorsed: "\e75f";
$pt-icon-envelope: "\2709";
$pt-icon-equals: "\e7d9";
$pt-icon-eraser: "\e773";
$pt-icon-error: "\e648";
$pt-icon-euro: "\20ac";
$pt-icon-exchange: "\e636";
$pt-icon-exclude-row: "\e6ea";
$pt-icon-expand-all: "\e764";
$pt-icon-export: "\e633";
$pt-icon-eye-off: "\e6cc";
$pt-icon-eye-on: "\e75a";
$pt-icon-eye-open: "\e66f";
$pt-icon-fast-backward: "\e6a8";
$pt-icon-fast-forward: "\e6ac";
$pt-icon-feed: "\e656";
$pt-icon-feed-subscribed: "\e78f";
$pt-icon-film: "\e6a1";
$pt-icon-filter: "\e638";
$pt-icon-filter-keep: "\e78c";
$pt-icon-filter-list: "\e6ee";
$pt-icon-filter-open: "\e7d7";
$pt-icon-filter-remove: "\e78d";
$pt-icon-flag: "\2691";
$pt-icon-flame: "\e7a9";
$pt-icon-flash: "\e6b3";
$pt-icon-floppy-disk: "\e6b7";
$pt-icon-flow-branch: "\e7c1";
$pt-icon-flow-end: "\e7c4";
$pt-icon-flow-linear: "\e7c0";
$pt-icon-flow-review: "\e7c2";
$pt-icon-flow-review-branch: "\e7c3";
$pt-icon-flows: "\e659";
$pt-icon-folder-close: "\e652";
$pt-icon-folder-new: "\e7b0";
$pt-icon-folder-open: "\e651";
$pt-icon-folder-shared: "\e653";
$pt-icon-folder-shared-open: "\e670";
$pt-icon-follower: "\e760";
$pt-icon-following: "\e761";
$pt-icon-font: "\e6b4";
$pt-icon-fork: "\e63a";
$pt-icon-form: "\e795";
$pt-icon-full-circle: "\e685";
$pt-icon-full-stacked-chart: "\e75e";
$pt-icon-fullscreen: "\e699";
$pt-icon-function: "\e6e5";
$pt-icon-gantt-chart: "\e6f4";
$pt-icon-geolocation: "\e640";
$pt-icon-geosearch: "\e613";
$pt-icon-git-branch: "\e72a";
$pt-icon-git-commit: "\e72b";
$pt-icon-git-merge: "\e729";
$pt-icon-git-new-branch: "\e749";
$pt-icon-git-pull: "\e728";
$pt-icon-git-push: "\e72c";
$pt-icon-git-repo: "\e748";
$pt-icon-glass: "\e6b1";
$pt-icon-globe: "\e666";
$pt-icon-globe-network: "\e7b5";
$pt-icon-graph: "\e673";
$pt-icon-graph-remove: "\e609";
$pt-icon-greater-than: "\e7e1";
$pt-icon-greater-than-or-equal-to: "\e7e2";
$pt-icon-grid: "\e6d0";
$pt-icon-grid-view: "\e6e4";
$pt-icon-group-objects: "\e60a";
$pt-icon-grouped-bar-chart: "\e75d";
$pt-icon-hand: "\e6de";
$pt-icon-hand-down: "\e6bb";
$pt-icon-hand-left: "\e6bc";
$pt-icon-hand-right: "\e6b9";
$pt-icon-hand-up: "\e6ba";
$pt-icon-header: "\e6b5";
$pt-icon-header-one: "\e793";
$pt-icon-header-two: "\e794";
$pt-icon-headset: "\e6dc";
$pt-icon-heart: "\2665";
$pt-icon-heart-broken: "\e7a2";
$pt-icon-heat-grid: "\e6f3";
$pt-icon-heatmap: "\e614";
$pt-icon-help: "\003F";
$pt-icon-helper-management: "\e66d";
$pt-icon-highlight: "\e6ed";
$pt-icon-history: "\e64a";
$pt-icon-home: "\2302";
$pt-icon-horizontal-bar-chart: "\e70c";
$pt-icon-horizontal-bar-chart-asc: "\e75c";
$pt-icon-horizontal-bar-chart-desc: "\e71d";
$pt-icon-horizontal-distribution: "\e720";
$pt-icon-id-number: "\e771";
$pt-icon-image-rotate-left: "\e73a";
$pt-icon-image-rotate-right: "\e73b";
$pt-icon-import: "\e632";
$pt-icon-inbox: "\e629";
$pt-icon-inbox-filtered: "\e7d1";
$pt-icon-inbox-geo: "\e7d2";
$pt-icon-inbox-search: "\e7d3";
$pt-icon-inbox-update: "\e7d4";
$pt-icon-info-sign: "\2139";
$pt-icon-inheritance: "\e7d5";
$pt-icon-inner-join: "\e7a3";
$pt-icon-insert: "\e66c";
$pt-icon-intersection: "\e765";
$pt-icon-ip-address: "\e772";
$pt-icon-issue: "\e774";
$pt-icon-issue-closed: "\e776";
$pt-icon-issue-new: "\e775";
$pt-icon-italic: "\e607";
$pt-icon-join-table: "\e738";
$pt-icon-key: "\e78e";
$pt-icon-key-backspace: "\e707";
$pt-icon-key-command: "\e705";
$pt-icon-key-control: "\e704";
$pt-icon-key-delete: "\e708";
$pt-icon-key-enter: "\e70a";
$pt-icon-key-escape: "\e709";
$pt-icon-key-option: "\e742";
$pt-icon-key-shift: "\e706";
$pt-icon-key-tab: "\e757";
$pt-icon-known-vehicle: "\e73c";
$pt-icon-lab-test: "\e90e";
$pt-icon-label: "\e665";
$pt-icon-layer: "\e6cf";
$pt-icon-layers: "\e618";
$pt-icon-layout: "\e60c";
$pt-icon-layout-auto: "\e60d";
$pt-icon-layout-balloon: "\e6d3";
$pt-icon-layout-circle: "\e60e";
$pt-icon-layout-grid: "\e610";
$pt-icon-layout-group-by: "\e611";
$pt-icon-layout-hierarchy: "\e60f";
$pt-icon-layout-linear: "\e6c3";
$pt-icon-layout-skew-grid: "\e612";
$pt-icon-layout-sorted-clusters: "\e6d4";
$pt-icon-learning: "\e904";
$pt-icon-left-join: "\e7a4";
$pt-icon-less-than: "\e7e3";
$pt-icon-less-than-or-equal-to: "\e7e4";
$pt-icon-lifesaver: "\e7c7";
$pt-icon-lightbulb: "\e6b0";
$pt-icon-link: "\e62d";
$pt-icon-list: "\2630";
$pt-icon-list-columns: "\e7b9";
$pt-icon-list-detail-view: "\e743";
$pt-icon-locate: "\e619";
$pt-icon-lock: "\e625";
$pt-icon-log-in: "\e69a";
$pt-icon-log-out: "\e64c";
$pt-icon-manual: "\e6f6";
$pt-icon-manually-entered-data: "\e74a";
$pt-icon-map: "\e662";
$pt-icon-map-create: "\e741";
$pt-icon-map-marker: "\e67d";
$pt-icon-maximize: "\e635";
$pt-icon-media: "\e62c";
$pt-icon-menu: "\e762";
$pt-icon-menu-closed: "\e655";
$pt-icon-menu-open: "\e654";
$pt-icon-merge-columns: "\e74f";
$pt-icon-merge-links: "\e60b";
$pt-icon-minimize: "\e634";
$pt-icon-minus: "\2212";
$pt-icon-mobile-phone: "\e717";
$pt-icon-mobile-video: "\e69f";
$pt-icon-moon: "\e754";
$pt-icon-more: "\e62a";
$pt-icon-mountain: "\e7b1";
$pt-icon-move: "\e693";
$pt-icon-mugshot: "\e6db";
$pt-icon-multi-select: "\e680";
$pt-icon-music: "\e6a6";
$pt-icon-new-drawing: "\e905";
$pt-icon-new-grid-item: "\e747";
$pt-icon-new-layer: "\e902";
$pt-icon-new-layers: "\e903";
$pt-icon-new-link: "\e65c";
$pt-icon-new-object: "\e65d";
$pt-icon-new-person: "\e6e9";
$pt-icon-new-prescription: "\e78b";
$pt-icon-new-text-box: "\e65b";
$pt-icon-ninja: "\e675";
$pt-icon-not-equal-to: "\e7e0";
$pt-icon-notifications: "\e624";
$pt-icon-notifications-updated: "\e7b8";
$pt-icon-numbered-list: "\e746";
$pt-icon-numerical: "\e756";
$pt-icon-office: "\e69b";
$pt-icon-offline: "\e67a";
$pt-icon-oil-field: "\e73f";
$pt-icon-one-column: "\e658";
$pt-icon-outdated: "\e7a8";
$pt-icon-page-layout: "\e660";
$pt-icon-panel-stats: "\e777";
$pt-icon-panel-table: "\e778";
$pt-icon-paperclip: "\e664";
$pt-icon-paragraph: "\e76c";
$pt-icon-path: "\e753";
$pt-icon-path-search: "\e65e";
$pt-icon-pause: "\e6a9";
$pt-icon-people: "\e63d";
$pt-icon-percentage: "\e76a";
$pt-icon-person: "\e63c";
$pt-icon-phone: "\260e";
$pt-icon-pie-chart: "\e684";
$pt-icon-pin: "\e646";
$pt-icon-pivot: "\e6f1";
$pt-icon-pivot-table: "\e6eb";
$pt-icon-play: "\e6ab";
$pt-icon-plus: "\002b";
$pt-icon-polygon-filter: "\e6d1";
$pt-icon-power: "\e6d9";
$pt-icon-predictive-analysis: "\e617";
$pt-icon-prescription: "\e78a";
$pt-icon-presentation: "\e687";
$pt-icon-print: "\2399";
$pt-icon-projects: "\e622";
$pt-icon-properties: "\e631";
$pt-icon-property: "\e65a";
$pt-icon-publish-function: "\e752";
$pt-icon-pulse: "\e6e8";
$pt-icon-random: "\e698";
$pt-icon-record: "\e6ae";
$pt-icon-redo: "\e6c4";
$pt-icon-refresh: "\e643";
$pt-icon-regression-chart: "\e758";
$pt-icon-remove: "\e63f";
$pt-icon-remove-column: "\e755";
$pt-icon-remove-column-left: "\e6fd";
$pt-icon-remove-column-right: "\e6fe";
$pt-icon-remove-row-bottom: "\e6fc";
$pt-icon-remove-row-top: "\e6fb";
$pt-icon-repeat: "\e692";
$pt-icon-reset: "\e7d6";
$pt-icon-resolve: "\e672";
$pt-icon-rig: "\e740";
$pt-icon-right-join: "\e7a5";
$pt-icon-ring: "\e6f2";
$pt-icon-rotate-document: "\e6e1";
$pt-icon-rotate-page: "\e6e2";
$pt-icon-satellite: "\e76b";
$pt-icon-saved: "\e6b6";
$pt-icon-scatter-plot: "\e73e";
$pt-icon-search: "\e64b";
$pt-icon-search-around: "\e608";
$pt-icon-search-template: "\e628";
$pt-icon-search-text: "\e663";
$pt-icon-segmented-control: "\e6ec";
$pt-icon-select: "\e616";
$pt-icon-selection: "\29bf";
$pt-icon-send-to: "\e66e";
$pt-icon-send-to-graph: "\e736";
$pt-icon-send-to-map: "\e737";
$pt-icon-series-add: "\e796";
$pt-icon-series-configuration: "\e79a";
$pt-icon-series-derived: "\e799";
$pt-icon-series-filtered: "\e798";
$pt-icon-series-search: "\e797";
$pt-icon-settings: "\e6a2";
$pt-icon-share: "\e62e";
$pt-icon-shield: "\e7b2";
$pt-icon-shop: "\e6c2";
$pt-icon-shopping-cart: "\e6c1";
$pt-icon-signal-search: "\e909";
$pt-icon-sim-card: "\e718";
$pt-icon-slash: "\e769";
$pt-icon-small-cross: "\e6d7";
$pt-icon-small-minus: "\e70e";
$pt-icon-small-plus: "\e70d";
$pt-icon-small-tick: "\e6d8";
$pt-icon-snowflake: "\e7b6";
$pt-icon-social-media: "\e671";
$pt-icon-sort: "\e64f";
$pt-icon-sort-alphabetical: "\e64d";
$pt-icon-sort-alphabetical-desc: "\e6c8";
$pt-icon-sort-asc: "\e6d5";
$pt-icon-sort-desc: "\e6d6";
$pt-icon-sort-numerical: "\e64e";
$pt-icon-sort-numerical-desc: "\e6c9";
$pt-icon-split-columns: "\e750";
$pt-icon-square: "\e686";
$pt-icon-stacked-chart: "\e6e7";
$pt-icon-star: "\2605";
$pt-icon-star-empty: "\2606";
$pt-icon-step-backward: "\e6a7";
$pt-icon-step-chart: "\e70f";
$pt-icon-step-forward: "\e6ad";
$pt-icon-stop: "\e6aa";
$pt-icon-stopwatch: "\e901";
$pt-icon-strikethrough: "\e7a6";
$pt-icon-style: "\e601";
$pt-icon-swap-horizontal: "\e745";
$pt-icon-swap-vertical: "\e744";
$pt-icon-symbol-circle: "\e72e";
$pt-icon-symbol-cross: "\e731";
$pt-icon-symbol-diamond: "\e730";
$pt-icon-symbol-square: "\e72f";
$pt-icon-symbol-triangle-down: "\e733";
$pt-icon-symbol-triangle-up: "\e732";
$pt-icon-tag: "\e61c";
$pt-icon-take-action: "\e6ca";
$pt-icon-taxi: "\e79e";
$pt-icon-text-highlight: "\e6dd";
$pt-icon-th: "\e667";
$pt-icon-th-derived: "\e669";
$pt-icon-th-disconnect: "\e7d8";
$pt-icon-th-filtered: "\e7c6";
$pt-icon-th-list: "\e668";
$pt-icon-thumbs-down: "\e6be";
$pt-icon-thumbs-up: "\e6bd";
$pt-icon-tick: "\2713";
$pt-icon-tick-circle: "\e779";
$pt-icon-time: "\23F2";
$pt-icon-timeline-area-chart: "\e6cd";
$pt-icon-timeline-bar-chart: "\e620";
$pt-icon-timeline-events: "\e61e";
$pt-icon-timeline-line-chart: "\e61f";
$pt-icon-tint: "\e6b2";
$pt-icon-torch: "\e677";
$pt-icon-tractor: "\e90c";
$pt-icon-train: "\e79f";
$pt-icon-translate: "\e759";
$pt-icon-trash: "\e63b";
$pt-icon-tree: "\e7b7";
$pt-icon-trending-down: "\e71a";
$pt-icon-trending-up: "\e719";
$pt-icon-truck: "\e90b";
$pt-icon-two-columns: "\e657";
$pt-icon-unarchive: "\e906";
$pt-icon-underline: "\2381";
$pt-icon-undo: "\238c";
$pt-icon-ungroup-objects: "\e688";
$pt-icon-unknown-vehicle: "\e73d";
$pt-icon-unlock: "\e626";
$pt-icon-unpin: "\e650";
$pt-icon-unresolve: "\e679";
$pt-icon-updated: "\e7a7";
$pt-icon-upload: "\e68f";
$pt-icon-user: "\e627";
$pt-icon-variable: "\e6f5";
$pt-icon-vertical-bar-chart-asc: "\e75b";
$pt-icon-vertical-bar-chart-desc: "\e71c";
$pt-icon-vertical-distribution: "\e721";
$pt-icon-video: "\e6a0";
$pt-icon-volume-down: "\e6a4";
$pt-icon-volume-off: "\e6a3";
$pt-icon-volume-up: "\e6a5";
$pt-icon-walk: "\e79d";
$pt-icon-warning-sign: "\e647";
$pt-icon-waterfall-chart: "\e6e6";
$pt-icon-widget: "\e678";
$pt-icon-widget-button: "\e790";
$pt-icon-widget-footer: "\e792";
$pt-icon-widget-header: "\e791";
$pt-icon-wrench: "\e734";
$pt-icon-zoom-in: "\e641";
$pt-icon-zoom-out: "\e642";
$pt-icon-zoom-to-fit: "\e67b";
