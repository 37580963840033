// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

:focus {
  @include focus-outline();
}

.#{$ns}-focus-disabled :focus {
  // override any focus outline anywhere
  // stylelint-disable declaration-no-important
  outline: none !important;

  // special override for checkbox etc which render focus on a separate element
  ~ .#{$ns}-control-indicator {
    outline: none !important;
  }
}
