// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "../../common/variables";

.#{$ns}-alert {
  max-width: $pt-grid-size * 40;
  padding: $pt-grid-size * 2;
}

.#{$ns}-alert-body {
  display: flex;

  .#{$ns}-icon {
    margin-top: 0;
    margin-right: $pt-grid-size * 2;
    font-size: $pt-icon-size-large * 2;
  }
}

.#{$ns}-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: $pt-grid-size;

  .#{$ns}-button {
    margin-left: $pt-grid-size;
  }
}
